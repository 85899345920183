import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'acs-alert',
  templateUrl: 'alert.component.html'
})
export class AlertComponent {
  @Input() closable: boolean;
  @Input() message: string;
  @Input() type: string;
  @Output() close = new EventEmitter<Event>();

  constructor() {
    this.closable = this.closable && !!this.close;
  }
}
