<div class="fade alert md-padding alert-{{type || 'warning'}}"
     ngClass="{
      'alert-dismissible': closable,
      'md-primary': type === 'warning'
    }"
     role="alert">
  <span>{{ message }}</span>
  <button *ngIf="closable" type="button" class="close" (click)="close.emit($event)">
    <span aria-hidden="true">&times;</span>
    <span class="sr-only">Close</span>
  </button>
  <div ng-transclude></div>
</div>
