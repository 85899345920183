import { AlertComponent } from '@app/notifications/shared/alert.component';
import { AUSnackBarComponent } from '@app/notifications/snackbars/au-snackbar.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { NotificationsComponent } from '@app/notifications/shared/notifications.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [AlertComponent, AUSnackBarComponent, NotificationsComponent],
  exports: [AlertComponent, NotificationsComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, SharedModule],
  providers: []
})
export class NotificationsModule {}
