import { Component, Input } from '@angular/core';
import { NoticeMessage } from '@app/shared/models/notice-messages.model';
import { getIdentifierForIterableItem } from '@app/shared/utilities/trackby.utility';

@Component({
  selector: 'acs-notifications',
  templateUrl: './notifications.component.html'
})
export class NotificationsComponent {
  @Input() messages: Array<NoticeMessage>;
  public getIdentifierForIterableItem = getIdentifierForIterableItem;

  constructor() {}

  public closeAlert(index: number): void {
    this.messages.splice(index, 1);
  }
}
